import React from 'react';

function App() {
  return (
    <div className="App">
      <div style={{ fontSize: '400%', textAlign: "center", marginTop: '100px' }}>SIASOFT</div>
    </div>
  );
}

export default App;
